<template>
  <v-form ref="form">
    <v-layout wrap justify-center>
      <v-flex xs12 lg8 pt-6>
        <v-card
          class="mx-auto elevation-2 pa-6"
          max-width="1088px"
          align="center"
          outlined
        >
          <v-layout>
            <v-flex
              xs4
              class="text-left nsbold"
              style="font-weight: 700; font-size: 20px"
            >
              <v-icon class="pr-2" color="#9A8CFC">mdi-account-plus</v-icon>Add
              New Students</v-flex
            >
            <v-flex
              xs4
              class="text-left nssbold"
              style="font-weight: 400; color: #797979; font-size: 18px"
              @click="$router.push({ path: 'student' })"
            >
              <v-icon class="pr-2">mdi-school-outline</v-icon>Students
              List</v-flex
            >
          </v-layout>

          <v-flex class="ma-6">
            <h3
              class="text-left nsbold"
              style="font-weight: 700; font-size: 18px"
            >
              Add Student Information
            </h3>
          </v-flex>
          <v-layout style="font-weight: 400">
            <v-flex xs12 pr-3>
              <v-text-field
                v-model="user.name"
                dense
                label="Name"
                outlined
                class="pl-4"
                :rules="[(v) => !!v || 'first name is required']"
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs6 px-4>
              <v-select
                :items="HostelList"
                v-model="user.studentHostel"
                label="Hostel"
                item-text="hostelName"
                item-value="_id"
                clearable
                outlined
                hide-details
                dense
                :rules="[rules.required]"
              ></v-select>
            </v-flex> -->
          </v-layout>
          <v-layout style="font-weight: 400">
            <v-flex xs6>
              <v-text-field
                v-model="user.admissionNo"
                dense
                label="Admission Number"
                outlined
                class="pl-4"
                :rules="[(v) => !!v || 'admission number is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 px-4>
              <!-- <v-text-field v-model="user.dob" dense label="DOB" outlined class="pr-4 pl-1"></v-text-field> -->
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="user.DOB"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="user.DOB"
                    label="DOB"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="user.DOB" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(user.DOB)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout style="font-weight: 400">
            <v-flex xs6 mx-4>
              <!-- <v-text-field v-model="user.gender" dense label="Gender" outlined class="pl-4"></v-text-field> -->
              <v-select
                :items="gender"
                v-model="user.gender"
                label="Gender"
                outlined
                dense
              ></v-select>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                dense
                v-model="user.religion"
                label="Religion"
                outlined
                class="pr-4"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout style="font-weight: 400">
            <v-flex xs6 mx-4>
              <v-select
                hide-details
                :items="classList"
                v-model="classid"
                label="Class"
                item-text="standardName"
                item-value="_id"
                clearable
                outlined
                :rules="[(v) => !!v || 'class is required']"
                dense
              ></v-select>
              <!-- <v-select
              :items="items"
              v-model="user.Class"
              label="Class"
              outlined
              dense
            ></v-select> -->
              <!-- <v-text-field
              v-model="user.Class"
              dense
              label="Class"
              outlined
              class="pl-4"
            ></v-text-field> -->
            </v-flex>
            <v-flex xs6>
              <v-text-field
                dense
                v-model="user.caste"
                label="Caste"
                outlined
                class="pr-4"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout style="font-weight: 400" justify-start>
            <!-- <v-flex xs6>
            <v-text-field
              class="pl-4"
              outlined
              dense
              v-model="user.password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Password"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-flex> -->
            <v-flex xs6 pb-4  pl-4>
              
              <v-select
                hide-detail
                :items="divisionList"
                v-model="user.division"
                clearable
                label="Division"
                item-text="divisionName"
                item-value="_id"
                :rules="[(v) => !!v || 'division is required']"
                outlined
                hide-details
                dense
              ></v-select>
              
            </v-flex>
            <v-flex xs6  px-4>
              <v-select
                :items="SchoolList"
                v-model="user.school"
                label="school"
                item-text="SchoolName"
                item-value="_id"
                clearable
                outlined
                dense
              ></v-select>
            </v-flex>
          </v-layout>
          <!-- <v-layout style="font-weight: 400" justify-start>
             <v-flex xs6  px-4>
              <v-text-field
                dense
                v-model="user.roomNumber"
                label="Room No."
                outlined
                class="pr-4"
              ></v-text-field>
            </v-flex>
          </v-layout> -->
          <div
            style="
              border-top: 3px dashed grey;
              border-top-width: 2px;
              width: 810px;
            "
          ></div>

          <!-------------------------------------------------->
          <v-layout>
            <v-flex class="ma-6">
              <h3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                Add Parents Information
              </h3>
            </v-flex>
          </v-layout>
          <!-- <v-layout style="font-weight: 400;">
				
				</v-layout> -->
          <v-layout style="font-weight: 400">
            <v-flex xs6>
              <v-text-field
                label="Father's Name"
                v-model="user.NameofFather"
                outlined
                :rules="[(v) => !!v || 'Father name is required']"
                dense
                class="pl-4"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                label="Mother's Name"
                v-model="user.NameofMother"
                outlined
                :rules="[(v) => !!v || 'Mother name is required']"
                dense
                class="px-4"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout style="font-weight: 400">
            <v-flex xs6>
              <v-text-field
                label="Contact"
                v-model="user.parentContactNumber"
                outlined
                :rules="rules.phoneRules"
                dense
                class="pl-4"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <!-- <v-layout style="font-weight: 400;">
				
					<v-flex xs6>
						<v-text-field label="Email" v-model="user.mothersemail" outlined :rules="[rules.email]" dense class="px-4"></v-text-field>
					</v-flex>
				</v-layout> -->

          <!------------------------------------------------->
          <v-flex class="nsregular px-16 mt-2 mb-16 pb-16">
            <v-btn
              style="font-size: 14px; font-weight: 400; width: 394px"
              color="#766BC0"
              dark
              depressed
              large
              @click="validateInput()"
              >SUBMIT
            </v-btn>
          </v-flex>
        </v-card>
        <v-snackbar
          :timeout="3000"
          :value="true"
          right
          color="red"
          v-model="snackbar"
        >
          <span style="font-size: 20px"> {{ msg }}</span>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      snackbar: false,
      gender: ["male", "female", "other"],
      classList: [],
      divisionList: [],
      HostelList: [],
      SchoolList: [],
      hostel: null,
      school: null,
      hostelName: null,
      dob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      show1: false,
      msg: null,
      classid: null,
      standardid:null,
      user: {
        name: null,
        standardid: null,
        division: null,
        gender: null,
        DOB: null,
        parentContactNumber: null,
        // studentHostel: null,
        religion: null,
        caste: null,
        NameofFather: null,
        NameofMother: null,
        admissionNo: null,
        school: null,
        // roomNumber: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      firstcheckbox: false,
      Myname: [],
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  mounted() {
    this.gethostel();
    this.getschool();
    this.getclass();
    if (this.cls) this.getDivision();
  },
  watch: {
    // myClass() {
    //   // this.page = 1;
    //   this.getDivision();
    // },
classid(){
  this.getDivision();
},
    //  user: {
    //    handler(cls){
    //      // do stuff
    //        this.getDivision();
    //    },
    //    deep: true
    // },

    div() {
      // this.page = 1;

      if (this.cls && this.div) this.search();
    },
  },
  methods: {
     validateInput() {
      if (!this.user.name) {
        this.msg="please fill name"
        this.showSnackBar = true;
      }
      if (!this.user.admissionNo) {
        this.msg="please fill admission number"
        this.showSnackBar = true;
      }
      if (!this.classid) {
        this.msg="please fill class"
        this.showSnackBar = true;
      }
      if (!this.user.division) {
        this.msg="please fill division"
        this.showSnackBar = true;
      }if (!this.user.NameofFather) {
        this.msg="please fill name of father"
        this.showSnackBar = true;
      }if (!this.user.NameofMother) {
        this.msg="please fill name of mother"
        this.showSnackBar = true;
      }if (this.user.parentContactNumber) { 
       if(this.user.parentContactNumber.length==10){
        this.msg="please fill contact properly"
        this.showSnackBar = true;
       }
      }
      this.add();
    },
    getschool() {
      axios({
        method: "get",
        url: "/school/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.SchoolList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getclass() {
      axios({
        method: "get",
        url: "/standard/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.classList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getDivision() {
      axios({
        method: "get",
        url: "/division/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.classid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.divisionList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    add() {
      if (this.$refs.form.validate()) {
        this.user.standardid = this.classid;
        axios({
          method: "post",
          url: "/add/student",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: this.user,
        })
          .then((response) => {
            if (response.data.status == true) {
              this.snackbar = true;
              this.msg = response.data.msg;
              this.$router.go(-1);
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    gethostel() {
      axios({
        method: "get",
        url: "/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostelName = this.HostelList[0]._id;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
  },
};
</script>